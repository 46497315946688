:root {
  --font: "Montserrat", sans-serif;
  --text-color: #868d9c;
  --title-color: #e8e9ee;
  --background: #111214;
  --box-color: #1b1f27;
  --shadow: 2px 7px 15px rgba(0, 0, 0, 0.2);
  --highlight-color: #0f3d8d;
  --table-color: rgba(29, 43, 68, 0.678);
}

* {
  box-sizing: border-box;
  
  &:focus {
    outline: none;
  }

  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background: #2b3342;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: var(--text-color);
  }
}

html {
  scroll-behavior: smooth;
  width: 100%;
}

body {
  width: 100%;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  background-color: #111214;
  color: var(--text-color);
  font-family: var(--font);
  font-weight: 400;
}

img {
  user-select: none;
  -webkit-user-drag: none;
}

a{
  color: var(--text-color);
}

h1, h2, h3, h4, h5, h6{
  color: var(--title-color);
  font-weight: 600;
}

h1{
  font-weight: 700;
}

table {
  width: 100%;
  border-collapse: collapse;
  background-color: var(--table-color);
  border-radius: 7px;
  overflow: hidden;
  font-size: .9rem;
  margin-bottom: 3rem;
  box-shadow: var(--shadow);

  thead {
    tr {
      th {
        border-bottom: 1px solid rgba(255, 255, 255, 0.2);
        text-align: left;
        font-weight: 500;
        padding: .9rem 1rem;
        white-space: nowrap;
        font-weight: 600;
        color: rgba(255, 255, 255, 0.7);
        text-transform: uppercase;
        border-right: 1px solid rgba(255, 255, 255, 0.03);
        
        &.small {
          width: 2rem;
        }
      }
    }
  }

  tbody {
    tr {
      &:nth-child(odd) {
        td {
          background-color: rgba(18, 21, 27, 0.2);
        }
      }

      td {
        padding: .6rem 1rem;
        font-size: .9rem;
        font-weight: 500;
        border-right: 1px solid rgba(255, 255, 255, 0.07);
        
        img {
          width: 1.2rem;
          height: 1.2rem;
          cursor: pointer;
          display: block;
          margin: 0 auto;
        }
      }
    }
  }
}


@media(max-width: 1024px) {
  * {
    cursor: default !important;

    &:focus {
      outline: none;
    }
  }
}

@media(max-width: 1024px){
  table{
    font-size: .8rem;
    width: 150vw;

    thead {
      tr {
        th {
          padding: .7rem 1rem;
        }
      }
    }
  }
}