.error-page{
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    h2{
        opacity: .2;
        margin: 3rem 0 0 0;
        text-transform: uppercase;
        font-weight: 600;
        padding-right: 3rem;
    }

    img{
        max-width: 350px;
    }
}