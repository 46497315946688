.loader {
  margin: auto;
  height: 50vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.4rem;
  color: white;
  opacity: 1;
  animation: fadeUp .5s ease-in-out forwards;
}

.doc-viewer {
  padding: 1.5rem;

  .header-box {
    height: 5rem;
    background-color: var(--box-color);
    display: flex;
    align-items: center;
    padding: 0 1.5rem;
    border-radius: 10px;
    box-shadow: var(--shadow);

    h1 {
      margin: 0;
    }
  }

  .content {
    display: flex;

    .nav-box {
      width: 24rem;
      margin: 1.5rem 0 0 0;
      background-color: var(--box-color);
      border-radius: 10px;
      padding: 1.5rem;
      box-shadow: var(--shadow);
      height: calc(100vh - 9.5rem);
      overflow-y: scroll;

      ul {
        list-style: none;
        padding-left: 0;
        margin: 0;
        font-size: .9rem;

        li {
          margin-top: 1.5rem;

          &:first-child {
            margin-top: 0;
          }

          a {
            border-bottom: 1px solid rgba(255, 255, 255, 0.05);
            color: #bac0ce;
            text-decoration: none;
            display: block;
            cursor: pointer;
            padding: 0.6rem 0.7rem;
            border-radius: 5px;
            font-weight: 600;

            &:hover {
              background-color: var(--highlight-color);
            }
          }
        }

        ul {
          padding-left: 1rem;
          opacity: .7;
          margin: 0;

          li {
            margin-top: 0;

            a {
              font-weight: 500;
            }
          }

          ul {
            font-size: .8rem;
          }
        }
      }

    }

    .content-box {
      width: calc(100% - 24rem);
      margin: 1.5rem 0 0 1.5rem;
      background-color: var(--box-color);
      border-radius: 10px;
      padding: 1.5rem;
      height: calc(100vh - 9.5rem);
      overflow-y: scroll;
      box-shadow: var(--shadow);

      h2:first-child {
        margin-top: 0;
      }

      h2 {
        margin-top: 4rem;
        cursor: pointer;
      }

      h2:hover::after {
        content: "copy";
        color: transparent;
        background-image: url('../../assets/icons/content_copy.svg');
        background-size: cover;
        width: 5px;
        height: 5px;
        filter: invert(100%);
      }
    }
  }
}

@keyframes fadeUp {
  0% {
    opacity: 0;
    transform: translateY(1rem);
  }

  100% {
    opacity: 1;
    transform: translateY(0rem);
  }
}

@media(max-width: 992px) {
  .doc-viewer {
    .content {
      .nav-box {
        width: 16rem;
        padding: 1.5rem .8rem;
      }

      .content-box {
        width: calc(100% - 16rem);

      }
    }
  }
}

@media(max-width: 599px) {
  .doc-viewer {
    .header-box{
      h1{
        font-size: 1.7rem;
      }
    }

    .content {
      .nav-box {
        display: none;
      }

      .content-box {
        width: 100%;
        margin-left: 0;

        h2{
          font-size: 1.3rem;
        } 

        p{
          font-size: .85rem;
        }

        h4{
          font-size: .9rem;
        }
      }
    }
  }
}
